<template>
  <div class="container" @touchmove="handleMove" @click="handleMove">
    <div class="content">
      <img src="../assets/images/logo.png" alt="" class="logo" />
      <div class="note">如果你已获得邀请码且尚未注册，请输入邀请码<br />如果你尚未获得邀请码，请耐心等待</div>
      <!-- <div>{{ code1 }}</div> -->
      <input type="text" id="code" maxlength="4" v-model="inputText" @keydown="keydown">
      <div class="code_bg" @click.stop="">
        <div class="code" @click="focus">{{ code.length > 0 ? code.substring(0, 1) : '' }}</div>
        <div class="space"></div>
        <div class="code" @click="focus">{{ code.length > 1 ? code.substring(1, 2) : '' }}</div>
        <div class="space"></div>
        <div class="code" @click="focus">{{ code.length > 2 ? code.substring(2, 3) : '' }}</div>
        <div class="space"></div>
        <div class="code" @click="focus">{{ code.length > 3 ? code.substring(3, 4) : '' }}</div>
        <!-- <input type="text" id="code" @click="inputClick"/> -->
      </div>
      <a class="link_btn0" @click="register">使用邀请码·开始注册</a>
      <div style="height: 0.52rem"></div>
      <span class="desc">如果你已完成注册，请点击<span hover style="color: #ff9900ff" @click="login">前往登录</span></span>
    </div>
  </div>
</template>

<script>
import { get, post } from '../config/http';
import api from '../config/api';
import $ from 'jquery'
export default {
  data() {
    return {
      note: '',
      code: '', inputText: ''
    };
  },
  watch: {
    inputText(newVar, oldVar) {
      if (newVar.match("^[a-z0-9A-Z]+$")) {
        console.log('')
      }
      else {
        this.inputText = oldVar
      }
      if (newVar == '') {
        this.inputText = ''
      }
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        this.code = this.inputText.toLocaleUpperCase()
      }
      // this.code = newVar.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, "").toLocaleUpperCase()
    }
  },
  props: {},
  mounted() {

    // var u = navigator.userAgent;
    // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
    // if (isAndroid) {
    //   var _this = this
    //   $('input').on('textInput', e => {
    //     var str = e.originalEvent.data
    //     var keyCode = e.originalEvent.data.charCodeAt(0);
    //     // _this.code1 = keyCode
    //     _this.code = e.originalEvent.data
    //     // keyCode is ASCII of character entered.
    //   })
    // }

    this.focus();


  },
  methods: {
    handleMove(){
      $('#code').blur();
    },
    test(){
      // alert('a')
    },
    test2(){
      // alert('b')
    },
    keydown(e) {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        return
      }
      // alert('ssa')
      if (e.keyCode != 8 && (e.keyCode < 48 && e.keyCode > 57) && (e.keyCode < 65 && e.keyCode > 90)) {
        return;
      }
      if (e.key == 'Backspace' || e.key == 'Delete') {
        if (this.code.length > 0) {
          this.code = this.code.substring(0, this.code.length - 1)
        }
        return
      }
      if (this.code.length >= 4) {
        return
      }
      var key = e.key.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, "").toLocaleUpperCase()
      this.code = this.code + key

    },
    blur(){
      var myElement = document.getElementById('code');
      myElement.blur();
    },
    focus() {
      var myElement = document.getElementById('code');
      myElement.focus();
    },
    register() {
      if (this.code.length < 4) {
        this.$toast('请输入验证码', require('../assets/images/toast_tip.png'))
        return;
      }

      var _this = this
      get(api.inviteCodeExist, {
        invite_code: this.code
      }).then(data => {
        if (data.success) {
          this.$router.push({ path: '/signup', query: { invite_code: this.code } });
        }
        else {
          this.$toast(data.message, require('../assets/images/toast_tip.png'))
        }

      }).catch(e => {
        this.$toast(e.data.error_message, require('../assets/images/toast_tip.png'))
      })


    },
    inputClick() {

      // alert('a')
    },
    login() {
      this.$router.push({ path: '/login' });
    },
    focusOpenKeyboard(el, timeout) {
      if (!timeout) {
        timeout = 100;
      }
      if (el) {
        // Align temp input element approximately where the input element is
        // so the cursor doesn't jump around
        var __tempEl__ = document.createElement('input');
        __tempEl__.style.position = 'absolute';
        __tempEl__.style.backgroundColor = 'red';
        __tempEl__.style.top = el.offsetTop + 7 + 'px';
        __tempEl__.style.left = el.offsetLeft + 'px';
        __tempEl__.style.height = 0;
        __tempEl__.style.opacity = 0;
        // Put this temp element as a child of the page <body> and focus on it
        document.body.appendChild(__tempEl__);
        __tempEl__.focus();

        // The keyboard is open. Now do a delayed focus on the target element
        setTimeout(function () {
          el.focus();
          el.click();
          // Remove the temp element
          // document.body.removeChild(__tempEl__);
        }, timeout);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.demo1{
  display: flex;
    color: #131314FF;
    width: 3.11rem;
    height: 0.56rem;
    align-items: center;
    justify-content: center;
    background: #FFF700;
    box-shadow: 0rem 0.2rem 0.2rem 0.06rem rgba(255, 153, 0, 0.4), 0rem 0.1rem 0rem -0.01rem #FF9900;
    border-radius: 0.28rem;
    border: 0.04rem solid #FF9900;
    font-size: 0.16rem;
    font-weight: bold;
    box-sizing: border-box;
    flex-shrink: 0;
}

.demo2{

}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  margin-top: 0.47rem;

  .logo {
    width: 1.67rem;
    height: 0.64rem;
  }

  .note {
    margin-top: 0.2rem;
    font-size: 0.14rem;
    color: #74747a;
    line-height: 0.24rem;
  }

  .desc {
    font-size: 0.14rem;
    color: #444447;
    line-height: 0.2rem;
  }

  input {
    width: 10;
    height: 2px;
    opacity: 0;
    left: 0;
    top: -100px;
    position: absolute;
  }

  .code_bg {
    display: flex;
    flex-direction: row;
    margin-top: 0.12rem;
    margin-bottom: 0.24rem;

    .space {
      width: 0.24rem;
    }


  }
}
</style>